import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout3';
import SEO from '../components/seo';
import Citysetting1 from '../components/citysetting/citysetting1';
import Citysetting2 from '../components/citysetting/citysetting2';
import Cityset from '../containers/cityset';
import Datalist from '../components/citysetting/citylist';
import Styles from './fsaas.module.css';

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};



const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
  const [value2, setValue2] = useStateWithLocalStorage(
    'cityset2'
  );
 
  const onChange = event => setValue(event.target.value);
  const onChange2 = event => setValue2(event.target.value2);
 
  return (
    <Layout>
            <SEO
        title="都市の設定"
        description="都市の設定画面です"
      />




<div className={Styles.container}>
    <header className={Styles.header}>
    <h1>はじめてのSAAS</h1>
    </header>
    <main className={Styles.main}>

      <div class="text-input">
          <input
            value={value}
            type="text"
            id="input1"
            placeholder="入力すると絞り込めます"
            onChange={onChange} 
            autocomplete="on"
            list="keywords"
          />
          <label for="input1">入力して選択</label>
          <Datalist />
        </div>
        <div class="text-input">
          <input
            value={value2}
            type="text"
            id="input1"
            placeholder="入力すると絞り込めます"
            onChange={onChange2} 
            autocomplete="on"
            list="keywords"
          />
          <label for="input1">入力して選択</label>
          <Datalist />
        </div>

    </main>
    <aside className={Styles.sidebar}>
      まずはじめに、福岡県糸島市と福岡県飯塚市の比較を見てみましょう。
      ２回目以降の方は、右から好きな都市を選択して、都市構造を分析できます。
    </aside>
    <box11 className={Styles.box11}>
      <img src="/gamenimage.png" alt="image01" style={{maxWidth:"100%"}}/>

    </box11>
    <box12 className={Styles.box12}>

      <Link to="/18/sogo">人口</Link>

    </box12>
    <footer className={Styles.footer}>
      
      aaa

    </footer>
</div>














    </Layout>
  );
};

export default App;